<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/company/xinli_adv.jpg" alt="">
    </div>
    <div class="current_menu">
      <div class="content">
        <div class="page_indicate">
          <div class="img" />
          <span>产品服务<span> > </span>{{ menuTitle }}</span>
        </div>
        <ul class="page_menu">
          <li
            v-for="(item,index) in menuList"
            :key="index"
            :class="{'active': index === menuActive}"
            @click="menuSelect(item,index)"
          >
            {{ item.name }}
            <div class="menu_block" />
          </li>
        </ul>
      </div>
    </div>
    <div class="page">
      <div v-if="info.body" class="ql-editor" v-html="info.body" />
      <!-- <serviceChild :list="contentList" /> -->
      <div v-for="(item,index) in contentList" :key="index">
        <div v-if="item.flag === '5'" class="text_img">
          <p class="title">{{ item.title }}</p>
          <p v-if="item.remark" class="text">{{ item.remark }}</p>
          <img v-if="item.iconUrl" :src="baseUrl + item.iconUrl" alt="">
          <div v-if="item.children">
            <div v-for="(cItem,cIndex) in item.children" :key="cIndex" class="text_img_c">
              <p class="title">{{ cItem.title }}</p>
              <p v-if="cItem.remark" class="text">{{ cItem.remark }}</p>
              <img v-if="cItem.iconUrl" :src="baseUrl + cItem.iconUrl" alt="">
              <div v-if="cItem.children">
                <div v-for="(ccItem,ccIndex) in cItem.children" :key="ccIndex" class="text_img_cc">
                  <p class="title">{{ ccItem.title }}</p>
                  <p v-if="ccItem.remark" class="text">{{ ccItem.remark }}</p>
                  <img v-if="ccItem.iconUrl" :src="baseUrl + ccItem.iconUrl" alt="">
                  <div v-if="ccItem.children">
                    <div v-for="(cccItem,cccIndex) in ccItem.children" :key="cccIndex" class="text_img_ccc">
                      <p class="title">{{ cccItem.title }}</p>
                      <p v-if="cccItem.remark" class="text">{{ cccItem.remark }}</p>
                      <img v-if="cccItem.iconUrl" :src="baseUrl + cccItem.iconUrl" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.flag === '17'" class="img_text">
          <img v-if="item.iconUrl" :src="baseUrl + item.iconUrl" alt="">
          <p class="title">{{ item.title }}</p>
          <p>{{ item.remark }}</p>
          <div v-if="item.children">
            <div v-for="(cItem,cIndex) in item.children" :key="cIndex" class="text_img_c">
              <p class="title">{{ cItem.title }}</p>
              <p v-if="cItem.remark" class="text">{{ cItem.remark }}</p>
              <img v-if="cItem.iconUrl" :src="baseUrl + cItem.iconUrl" alt="">
              <div v-if="cItem.children">
                <div v-for="(ccItem,ccIndex) in cItem.children" :key="ccIndex" class="text_img_cc">
                  <p class="title">{{ ccItem.title }}</p>
                  <p v-if="ccItem.remark" class="text">{{ ccItem.remark }}</p>
                  <img v-if="ccItem.iconUrl" :src="baseUrl + ccItem.iconUrl" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import serviceChild from './../components/serviceChild.vue'
import { getMenu, getMainContent } from '@/plugin/api.js'
export default {
  // components: { serviceChild },
  data() {
    return {
      menuList: [],
      menuActive: 0,
      menuTitle: '',
      info: {},
      contentList: [],
      baseUrl: '/website'
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) this.routeChange()
      }
    }
  },
  mounted() {
    window.scrollTo({
      top: 0
    })
    this.getWebInfo()
  },
  methods: {
    routeChange() {
      const url = this.$route.query.url === '123' ? '129' : this.$route.query.url
      const urlType = this.$route.query.urlType
      const num = this.menuList.findIndex(item => {
        return item.url === url
      })
      this.menuActive = num < 0 ? 0 : num
      this.menuTitle = this.menuList[this.menuActive].name
      this.getInfo(url, urlType)
    },
    menuSelect(item, index) {
      this.menuActive = index
      this.menuTitle = item.name
      this.$router.push({ path: '/xljrProductServices', query: { url: item.url, urlType: item.urlType }})
    },
    getWebInfo() {
      getMenu({ tenantId: 'xljr' }).then(res => {
        const menu = res.data.data.menuList.find(item => {
          return item.name === '产品服务'
        })
        this.menuList = menu.children
        this.routeChange()
      })
    },
    getInfo(url, urlType) {
      getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        const obj = res.data.data.contentMainList[0]
        this.info = obj
        this.contentList = obj.contentItemTreeList
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .ql-editor{
    p{
      white-space: pre-wrap;
    }
    img{
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
.page{
  width: 1200px;
  margin: 20px auto;
  background-color: #fff;
  padding: 35px 50px;
  text-align: left;
  .text_img,.img_text{
    margin-bottom: 20px;
  }
  .text_img{
    text-align: center;
    margin-bottom: 30px;
    img{
      max-width: 100%;
      margin: 10px auto 0px auto;
    }
    .title{
      font-weight: 700;
      text-align: left;
    }
    .text{
      text-align: left;
      margin-bottom: 10px;
      white-space: pre-wrap;
    }
    &>.title{
      font-size: 24px;
      margin-bottom: 10px;
    }
    &>.text{
      margin-bottom: 20px;
    }
    .text_img_c{
      margin: 10px;
      &>.title{
        font-size: 20px;
        margin-bottom: 10px;
      }
      .text_img_cc{
        margin: 10px;
        &>.title{
          font-size: 18px;
          margin-bottom: 10px;
        }
        .text_img_ccc{
          margin: 10px;
          &>.title{
            font-size: 16px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
.info{
  padding: 50px 0 30px 0;
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  color: #101010;
  font-size: 14px;
  text-align: center;
  img{
    max-width: 100%;
    margin-bottom: 0 auto 20px auto;
  }
  .textera{
    margin: 20px 40px;
    text-align: left;
    margin-bottom: 40px;
    p{
      line-height: 25px;
    }
    img{
      max-width: 100%;
    }
  }
  .title{
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 700;
  }
}
</style>
